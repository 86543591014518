import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  Button,
  ListItemAvatar,
  ListItem,
  List,
  Avatar,
  ListItemText,
  ListItemButton,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
  Snackbar,
  Alert,
  DialogTitle,
  DialogContentText,
  CircularProgress,
  RadioGroup,
  FormControl,
  Radio,
  styled,
  Badge,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
import Dropzone from 'react-dropzone';
import CancelIcon from '@mui/icons-material/Cancel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import * as chatAction from '../../redux/action/chatAction';
import { ReactComponent as LikeGreenIcon } from '../icons/ico like green.svg';
import { ReactComponent as LikeIcon3 } from '../icons/ico-like_gray.svg';
import { ReactComponent as CommentIcon } from '../icons/ico_chat.svg';
import { ReactComponent as LikeIcon2 } from '../icons/ico_like.svg';
import { ReactComponent as LikeedGreen } from '../icons/gallery_liked_green.svg';
import { ReactComponent as ChatIcon } from '../icons/ico_comment.svg';
import { ReactComponent as TimerIcon } from '../icons/ico_timer_big.svg';
import { ReactComponent as TimerSmallIcon } from '../icons/ico_timer_small.svg';
import { ReactComponent as TimerPink } from '../icons/ico_pink_timer.svg';
import { ReactComponent as SubmitIcon } from '../icons/ico_btn_submit.svg';
import { ReactComponent as PlaceHolder } from '../icons/photo_carousel_placeholder.svg';
import { ReactComponent as GalleryLockIcon } from '../icons/ico super private.svg';
import useStyles from '../../styles/myProfile';
import { connect } from 'react-redux';
import * as galleryAction from '../../redux/action/galleryAction';
import * as commonAction from '../../redux/action/commonAction';
import * as userDetailsAction from '../../redux/action/userDetailsAction';
import { getTimeStamp } from '../../utils';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import PublicIcon from '@mui/icons-material/Public';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ConfirmationAlert from '../common/confirmationAlert';
import { copyImageToClipboard } from 'copy-image-clipboard';
import SocialShare from '../../utils/socialShare';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import OtpVerifyAlert from '../../utils/otpVerifyAlert';
const { ClipboardItem } = window;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    bottom: '18%',
    right: '18%',
  },
}));
const GalleryCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [uploadModal, setUploadModal] = useState(false);
  const [files, setFile] = useState([]);
  const [awaitingModal, setAwaitingModal] = useState(false);
  const [commentButtonStatus, setCommentButtonStatus] = useState(true);
  const [othermainPicture, setOtherMainPicture] = useState('');
  const [otherUserData, setOtherUserData] = useState([]);
  const [currentImageID, setCurrentImageID] = useState('');
  const [pictureSource, setPictureSource] = useState('');
  const [pictureLikeAction, setPictureLikeAction] = useState(0);
  const [pictureLikeCount, setPictureLikeCount] = useState(0);
  const [pictureCommentCount, setPictureCommentCount] = useState(0);
  const [pictureDescription, setDescription] = useState('');
  const [commentData, setCommentData] = useState('');
  const [LevelCommentData, setLevelCommentData] = useState('');
  const [commentDetails, setCommentDetails] = useState([]);
  const [imageApprovedStatus, setImageApprovedStatus] = useState(0);
  const [imageVisibilityStatus, setImageVisibilityStatus] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const enableGalleryPicMenu = Boolean(anchorEl);
  const [myDetails, setMyDetails] = useState([]);
  const [hideCarosual, setHideCarosual] = useState(false);
  const [hideMainPic, setHideMainPic] = useState(false);
  const [hidePictureAction, setHidePictureAction] = useState(false);
  const [hideSettings, setHideSettings] = useState(false);
  const [picSecurity, setPicSecurity] = useState('');
  const [CaptionData, setCaptionData] = useState('');
  const [galleryAlert, setGalleryAlert] = useState(false);
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);
  const [alertCloseButton, setAlertCloseButton] = useState(false);
  const [alertConfirmButtonText, setAlertConfirmButtonText] = useState('');
  const [alertCloseButtonText, setAlertCloseButtonText] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [privacySettingsModal, setPrivacySettingsModal] = useState(false);
  const [replyCommentInput, setReplyComment] = useState({
    id: '',
    replyInputStatus: false,
  });
  const [showReplyComments, setShowReplyComments] = useState({
    id: '',
    replyCommentStatus: false,
  });
  const [imagePathCopied, setImagePathCopied] = useState(false);
  const [sendInChatAlert, setSendInChat] = useState(false);
  const [selectedChatId, setSelectedChatID] = React.useState('');
  const [snackBarText, setSnackbarText] = useState('');
  const [downloadableUrl, setDownloadurl] = useState('');
  const [imageCopyUrl, setImageCopyurl] = useState('');
  const [shareDialouge, setShareDialouge] = useState(false);
  const [loaderStatus, setLoader] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [commentButtonDisabled, setCommentButtonDisabled] = useState(false)
  const [accVerifiedStatus, setAccVerifyStatus] = useState(false)
  const [parentCommentId, setParentCommentId] = useState({})
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getSlidePercentage = () => {
    let match = 100;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (useMediaQuery(theme.breakpoints.up('md'))) {
      match = 25;
    } else {
      match = 100;
    }
    return match;
  };

  const handleClose = () => {
    if (imagePathCopied) {
      setImagePathCopied(false);
      setSnackbarText('');
    }

    if (sendInChatAlert) {
      setSendInChat(false);
      setSelectedChatID('');
    }
    if (files.length > 0) {
      setFile([]);
    }
    if (picSecurity != '') {
      setPicSecurity('');
    }
    if (privacySettingsModal) {
      setPrivacySettingsModal(false);
    }
    setUploadModal(false);
    setAwaitingModal(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.photoList.length > 0) {
      setMyDetails(props.photoList);
      setCurrentImageID(props.photoList[1]?.gallery_id);
      setOtherMainPicture(props.photoList[1]?.pictures);
      setPictureSource(props.photoList[1]?.type);
      setPictureLikeCount(props.photoList[1]?.likes_count);
      setPictureCommentCount(props.photoList[1]?.comment_count);
      setImageApprovedStatus(props.photoList[1]?.is_approved);
      setImageVisibilityStatus(props.photoList[1]?.visibility);
      setDescription(props.photoList[1]?.description);
      setDownloadurl(props.photoList[1]?.download_url);
      setImageCopyurl(props.photoList[1]?.path_url);
    }
  }, [props.photoList]);

  useEffect(() => {
    if (props.otherUserDetails != null) {
      if (props.otherUserDetails[0]?.other_gallery_image != null) {
        setOtherMainPicture(props.otherUserDetails[0]?.other_gallery_image.original);
      } else {
        setOtherMainPicture('')
      }
      setCurrentImageID(props.otherUserDetails[0]?.gallery_id);
      setPictureSource(props.otherUserDetails[0]?.type);
      setPictureLikeAction(props.otherUserDetails[0]?.is_photo_liked);
      setPictureLikeCount(props.otherUserDetails[0]?.likes_count);
      setPictureCommentCount(props.otherUserDetails[0]?.comment_count);
      setImageApprovedStatus(props.otherUserDetails[0]?.is_approved);
      setDescription(props.otherUserDetails[0]?.description);
      //      props.getGalleryPhotoDetails(
      //   localStorage.getItem('userToken'),
      //   props.otherUserDetails[0]?.gallery_id
      // );
    }
  }, [props.otherUserDetails]);

  useEffect(() => {
    if (props.likePhotoStatus) {
      props.getUserByID(
        localStorage.getItem('userToken'),
        localStorage.getItem('currentID')
      );
      props.profileChangeStatusNull()
    }
  }, [props.likePhotoStatus]);

  useEffect(() => {
    if (
      localStorage.getItem('userToken') != null &&
      window.location.pathname === '/my-profile'
    ) {
      props.getGalleryPhotoList(localStorage.getItem('userToken'));
    }
  }, []);

  useEffect(() => {
    if (currentImageID !== null || currentImageID !== undefined) {
      props.getGalleryPhotoDetails(
        localStorage.getItem('userToken'),
        currentImageID
      );
    }
  }, [currentImageID]);
  useEffect(() => {
    if (
      localStorage.getItem('userToken') != null &&
      window.location.pathname === '/user-profile'
    ) {
      props.getUserByID(
        localStorage.getItem('userToken'),
        localStorage.getItem('currentID')
      );

    }
  }, []);

  useEffect(() => {
    setOtherUserData(props.otherUserDetails);
  }, [props.otherUserDetails]);

  useEffect(() => {
    if (props.photoUploadStatus) {
      props.getGalleryPhotoList(localStorage.getItem('userToken'));
      props.profileChangeStatusNull();
      setUploadModal(false);
      setFile([]);
      setLoader(false)
      setHideCarosual(false);
      setHideMainPic(false);
      setHidePictureAction(false);
      setHideSettings(false);
      setPicSecurity('')
      setCaptionData('')
    } else if (props.photoUploadStatus === false) {
      setLoader(false)
      setUploadModal(false);
      setFile([]);
      setPicSecurity('')
      setCaptionData('')
      setGalleryAlert(true);
      setAlertTitle(t("somethingWentWrong"));
      setAlertText(t("galleryImgNotUploadedTryLater"));
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      props.profileChangeStatusNull();
    }
  }, [props.photoUploadStatus]);

  useEffect(() => {
    if (props.commentStatus === true) {
      setCommentButtonDisabled(false)
      props.getGalleryPhotoDetails(
        localStorage.getItem('userToken'),
        currentImageID
      );
      setCommentData('');
      setLevelCommentData('');
      setReplyComment({ id: '', replyInputStatus: false });
      setImagePathCopied(true);
      setSnackbarText(t("commentedSuccessfully"));
      setParentCommentId({})
      props.profileChangeStatusNull();
    } else if (props.commentStatus === `You Don't have Enough Coins to Comment Photos`) {
      setCommentData('');
      setLevelCommentData('');
      setReplyComment({ id: '', replyInputStatus: false });
      setImagePathCopied(true);
      setSnackbarText(t("dontHaveCoinsForComment"));
      setCommentButtonDisabled(false)
      props.profileChangeStatusNull();
    }
  }, [props.commentStatus]);

  useEffect(() => {
    if (props.commentDetails) {
      setCommentDetails(props.commentDetails);
    }
  }, [props.commentDetails]);
  const getUploadModal = () => {
    setHideCarosual(true);
    setHideMainPic(true);
    setHidePictureAction(true);
    setHideSettings(true);
  };

  useEffect(() => {
    if (props.likeForCommentStatus) {
      props.getGalleryPhotoDetails(
        localStorage.getItem('userToken'),
        currentImageID
      );
    }

  }, [props.likeForCommentStatus]);
  const notUploadAlert = () => {
    if (!props.galleryUnlockedStatus) {
      setGalleryAlert(true);
      setAlertTitle(t("galleryUploadNotAllowed"));
      setAlertText('You need to unlock gallery upload option on SkillTree.');
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
    } else {
      setGalleryAlert(true);
      setAlertTitle(t("galleryUploadNotAllowed"));
      setAlertText(t("reachedUploadLimit"));
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
    }
  };
  const renderImages = () => {
    return myDetails.map((item) => (
      <div key={item.id} className={classes.carouselSec}>
        {item.type === 'upload' ? (
          <div
            className={classes.images}
            onClick={
              props.eligibleCount > 0
                ? () => getUploadModal()
                : () => notUploadAlert()
            }
          >
            <PlaceHolder
              title={
                props.eligibleCount === 0
                  ? t("galleryUploadNotAllowed")
                  : t("clickHereToUpload")
              }
            />
          </div>
        ) : (
          <div
            onClick={() => getDetails(item)}
            className={classes.images}
            style={{
              background: `url("${encodeURI(item.mobile_image['150'])}") no-repeat`,
            }}
          >
            {item.is_approved === 0 && item.visibility === 0 ? (
              <TimerSmallIcon className={classes.timerSmall} />
            ) : (item.is_approved === 0 && item.visibility === 1) || (item.is_approved === 0 && item.visibility === 2) ? <GalleryLockIcon /> :
              (item.is_approved === 1 && item.visibility === 1) || (item.is_approved === 1 && item.visibility === 2) ? <GalleryLockIcon /> : ''}
          </div>
        )}
        {item.type != 'upload' ? (
          <div className={classes.likeSec}>
            <span className={classes.likeCounts}>{item.likes_count}</span>
            <LikeIcon2 />
          </div>
        ) : (
          ''
        )}
      </div>
    ));
  };
  const renderOtherUserImages = () => {
    if (otherUserData.length > 0) {
      return otherUserData.map((item) =>
        item.other_gallery_image !== null ? (
          <div key={item.id} className={classes.carouselSec}>
            <div
              onClick={() => getDetails(item)}
              className={classes.images}
              style={{
                background: `url("${encodeURI(item.other_gallery_image['1024'])}") no-repeat`,
                backgroundSize: '135px 120px',
              }}
            >
              {item.is_approved ? (
                ''
              ) : (
                <TimerSmallIcon className={classes.timerSmall} />
              )}
            </div>
            <div className={classes.likeSec}>
              <span className={classes.likeCounts}>{item.likes_count}</span>
              {item.is_photo_liked === 0 ? <LikeIcon2 /> : <LikeedGreen />}
            </div>
          </div>
        ) : (
          ''
        )
      );
    }
  };

  const changeCommentButtonStatus = () => {
    setShowReplyComments({ id: '', replyCommentStatus: false });
    setReplyComment({ id: '', replyInputStatus: false });
    if (commentButtonStatus) {
      setCommentButtonStatus(false);
    } else {
      setCommentButtonStatus(true);
    }
  };

  const getDetails = (item) => {
    setHideCarosual(false);
    setHideMainPic(false);
    setHidePictureAction(false);
    setHideSettings(false);
    setPictureSource(item.type);
    if (!props.myProfile) {
      setOtherMainPicture(item.other_gallery_image?.original)
    } else {
      setOtherMainPicture(item.pictures);
    }
    setCurrentImageID(item.gallery_id);
    setPictureLikeAction(item.is_photo_liked);
    setPictureLikeCount(item.likes_count);
    setPictureCommentCount(item.comment_count);
    setImageApprovedStatus(item.is_approved);
    setImageVisibilityStatus(item.visibility);
    setDescription(item.description);
    if (props.myProfile) {
      setDownloadurl(item.download_url);
    }
    props.getGalleryPhotoDetails(
      localStorage.getItem('userToken'),
      item.gallery_id
    );
  };

  const galleryUpload = () => {
    setLoader(true)
    let data = new FormData();

    files.forEach(function (item, index) {
      data.append('gallery', item);
    });
    data.append('description', CaptionData);
    data.append(
      'visibility',
      picSecurity === 'public' ? 0 : picSecurity === 'private' ? 1 : 2
    );
    props.galleryPhotosUpload(localStorage.getItem('userToken'), data);
  };

  const likeOtherPhotos = () => {
    let data = new FormData();
    data.append('member_id', localStorage.getItem('currentID'));
    data.append('photo_id', currentImageID);
    data.append('action', pictureLikeAction === 0 ? 1 : 0);
    props.LikeOtherMemberPhotos(data, localStorage.getItem('userToken'));
  };

  const handleCommentChange = (e) => {
    if (e.target.name === 'comment') {
      setCommentData(e.target.value);
    } else if (e.target.name === 'Levelcomment') {
      setLevelCommentData(e.target.value);
    }
  };

  const submitParentComment = () => {
    if (localStorage.getItem('AccVerifiedStatus') === '1') {
      setCommentButtonDisabled(true)
      let data = new FormData();
      data.append('member_id', localStorage.getItem('currentID'));
      data.append('photo_id', currentImageID);
      data.append('comment', commentData);
      data.append('parent_comment_id', 0);
      data.append('comment_level', 0);
      props.CommentOtherMemberPhotos(data, localStorage.getItem('userToken'));
    } else {
      setAccVerifyStatus(true)
    }

  };

  const removeVerifyAlert = () => {
    if (LevelCommentData !== '') {
      setAccVerifyStatus(false)
      submitComment(parentCommentId)
    } else if (commentData !== '') {
      setAccVerifyStatus(false)
      submitParentComment()
    }
  }

  const submitComment = (item) => {
    if (localStorage.getItem('AccVerifiedStatus') === '1') {
      setCommentButtonDisabled(true)
      let data = new FormData();
      data.append('member_id', localStorage.getItem('currentID'));
      data.append('photo_id', currentImageID);
      data.append('comment', LevelCommentData);
      data.append('parent_comment_id', item.id);
      data.append('comment_level', 1);
      props.CommentOtherMemberPhotos(data, localStorage.getItem('userToken'));
    } else {
      setAccVerifyStatus(true)
      setParentCommentId(item)
    }
  };
  const getUploadStatus = () => {
    return Array.from(Array(props.eligibleCount), (e, i) => {
      return (
        <Grid
          item
          xs={12}
          md={4}
          sm={4}
          lg={4}
        // sx={{
        //   display: 'flex',
        //   justifyContent: 'space-between',
        // }}
        >
          <section
            className={classes.uploadDragableUpload}
            onClick={() => setUploadModal(true)}
          >
            <div className={classes.uploadPlaceholderCt}></div>
            <AddCircleIcon className={classes.addImage} />
          </section>
        </Grid>
      );
    });
  };
  const handleCaptionChange = (e) => {
    setCaptionData(e.target.value);
  };

  const changeAsProfilePic = () => {
    let data = new FormData();
    data.append('gallery_id', currentImageID);
    props.setAsProfilePic(data, localStorage.getItem('userToken'));
  };

  useEffect(() => {
    if (props.setAsProfilePicStatus) {
      props.getGalleryPhotoList(localStorage.getItem('userToken'));
      setGalleryAlert(true);
      setAlertTitle(t("profilePicUpdated"));
      setAlertText(t("Yournewprofilepictureupdated."));
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      props.userGeneralDetailsService(localStorage.getItem('userToken'))
    } else if (props.setAsProfilePicStatus === false) {
      setGalleryAlert(true);
      setAlertTitle(t("somethingWentWrong"));
      setAlertText(t("ProfilePictureNotUpdatedTryagainlater"));
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
    }
  }, [props.setAsProfilePicStatus]);
  const CloseAlert = () => {
    setGalleryAlert(false);
    setAlertConfirmButton(false);
    setAlertCloseButton(false);
    setAlertConfirmButtonText('');
    setAlertCloseButtonText('');
    setAlertTitle('');
    setAlertText('');
    props.profileChangeStatusNull();
  };
  const alertConfirmFunction = () => { };

  const confirmPrivacySetting = () => {
    setPrivacySettingsModal(true);
  };

  const changeProfilePicVisibility = (visibilityID) => {
    let data = new FormData();
    data.append('gallery_id', currentImageID);
    data.append(
      'visibility',
      picSecurity === 'public' ? 0 : picSecurity === 'private' ? 1 : 2
    );
    props.changePicVisibility(data, localStorage.getItem('userToken'));
  };

  useEffect(() => {
    if (props.picVisibilityChangeStatus) {
      props.getGalleryPhotoList(localStorage.getItem('userToken'));
      setGalleryAlert(true);
      setAlertTitle(t("privacyStatusChanged"));
      setAlertText(t("privacyStatusChangedContent"));
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
    } else if (props.picVisibilityChangeStatus === false) {
      setGalleryAlert(true);
      setAlertTitle(t("somethingWentWrong"));
      setAlertText(t("PictureStatusNotUpdatedTryagainlater"));
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
    }
  }, [props.picVisibilityChangeStatus]);

  const deletePicture = () => {
    setGalleryAlert(true);
    setAlertTitle(t("deletePicture"));
    setAlertText(t("sureWantDelete"));
    setAlertConfirmButton(true);
    setAlertCloseButton(true);
    setAlertConfirmButtonText(t("delete"));
    setAlertCloseButtonText(t("Close"));
  };

  const deletePicConfirm = () => {
    let data = new FormData();
    data.append('gallery_id', currentImageID);
    data.append('status', 0);
    props.deleteGalleryPicture(data, localStorage.getItem('userToken'));
  };

  useEffect(() => {
    if (props.picDeleteStatus) {
      props.getGalleryPhotoList(localStorage.getItem('userToken'));
      setGalleryAlert(true);
      setAlertTitle(t("deletePicture"));
      setAlertText(t("deleteSuccessfully"));
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
    } else if (props.picDeleteStatus === false) {
      setGalleryAlert(true);
      setAlertTitle(t("deletePicture"));
      setAlertText(t("somethingWentWrong"));
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
    }
  }, [props.picDeleteStatus]);




  const likeGalleryComments = (id) => {
    let data = new FormData();
    data.append('comment_id', id);
    data.append('action', 1);
    props.LikeForComment(data, localStorage.getItem('userToken'));
  };

  const handleCommentReply = (id) => {
    setShowReplyComments({ id: '', replyCommentStatus: false });

    if (id != replyCommentInput.id) {
      setReplyComment({ id: id, replyInputStatus: true });
    } else {
      setReplyComment({ id: '', replyInputStatus: false });
    }
  };

  const handleShowReplyComments = (id) => {
    setReplyComment({ id: '', replyInputStatus: false });
    if (id != showReplyComments.id) {
      setShowReplyComments({ id: id, replyCommentStatus: true });
    } else {
      setShowReplyComments({ id: '', replyCommentStatus: false });
    }
  };
  const getImageBlobFromUrl = async (url) => {
    const fetchedImageData = await fetch(url);
    const blob = await fetchedImageData.blob();
    return blob;
  };

  const copyToClipboard = async () => {
    copyImageToClipboard(
      `${othermainPicture}`,
    )
      .then(() => {
        setImagePathCopied(true)
        setSnackbarText(t("imageCopiedtoClipboard"));
      })
      .catch((e) => {
        console.log('Error: ', e.message)
      })
  };

  const sendInChat = () => {
    setSendInChat(true);
    props.getChatHistoryData(localStorage.getItem('userToken'));
  };

  const handleChange = (event) => {
    setSelectedChatID(event.target.value);
  };
  const sendGalleryPic = () => {
    let data = new FormData();
    data.append('to_user_id', selectedChatId);
    data.append('image', currentImageID);
    if (currentImageID != '') {
      props.PostNewMessage(data, localStorage.getItem('userToken'));
    }
  };

  useEffect(() => {
    if (props.postMessageStatus !== null) {
      if (sendInChatAlert) {
        setSendInChat(false);
        setSelectedChatID('');
        setImagePathCopied(true);
        setSnackbarText(props.postMessageStatus);
        setTimeout(() => {
          handleClose();
        }, 6000);
      }
    }
  }, [props.postMessageStatus]);
  const socialSharing = (data) => {
    setShareDialouge(data);
  };
  return (
    <>
      <OtpVerifyAlert accVerifiedStatus={accVerifiedStatus} removeOTPAlert={removeVerifyAlert} />
      <SocialShare
        shareDialouge={shareDialouge}
        setSocialShareing={socialSharing}
        url={`${othermainPicture}`}
        description={pictureDescription}
      />
      <Snackbar
        open={imagePathCopied}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {snackBarText}
        </Alert>
      </Snackbar>
      <ConfirmationAlert
        alert={galleryAlert}
        confirmButton={alertConfirmButton}
        closeButton={alertCloseButton}
        confirmButtonText={alertConfirmButtonText}
        closeButtonText={alertCloseButtonText}
        alertTitle={alertTitle}
        alertText={alertText}
        closeFunc={CloseAlert}
        confirmFunc={
          alertConfirmButton ? deletePicConfirm : alertConfirmFunction
        }
        buttonDisabled={buttonDisabled}
      />
      <Card sx={{ borderRadius: '10px' }}>
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            sx={{ margin: '20px 0px' }}
          >
            <Typography variant="body1" component="h6">
              {t("photoGallery")}
            </Typography>
            {props.myProfile && !hideSettings ? (
              <MoreHorizIcon
                style={{ cursor: 'pointer' }}
                onClick={(e) => handleClick(e)}
                aria-controls={
                  enableGalleryPicMenu ? 'account-menu' : undefined
                }
                aria-haspopup="true"
                aria-expanded={enableGalleryPicMenu ? 'true' : undefined}
              />
            ) : (
              ''
            )}
          </Grid>
          {!hideMainPic ? (
            <div
              className={classes.mypic}
              style={{
                background: `url("${encodeURI(othermainPicture)}") no-repeat`,
              }}
            >
              {imageApprovedStatus === 0 && imageVisibilityStatus === 0 ? (
                <TimerIcon
                  style={{ position: 'absolute' }}
                  onClick={() => setAwaitingModal(true)}
                />
              ) : (imageApprovedStatus == 0 && imageVisibilityStatus === 1) || (imageApprovedStatus == 0 && imageVisibilityStatus === 2) ?
                <GalleryLockIcon
                  style={{ position: 'absolute' }}
                  onClick={() => setAwaitingModal(true)}
                /> : (imageApprovedStatus == 1 && imageVisibilityStatus === 1) || (imageApprovedStatus == 1 && imageVisibilityStatus === 2) ?
                  <GalleryLockIcon
                    style={{ position: 'absolute' }}
                    onClick={() => setAwaitingModal(false)}
                  /> : ''}
              {awaitingModal ? (
                <Card className={classes.awaitingCard}>
                  <TimerPink className={classes.timerpink} />
                  <CancelIcon
                    className={classes.closeIcon}
                    onClick={handleClose}
                  />
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.text}
                    sx={{
                      marginTop: '20px',
                      marginBottom: '20px',
                      textAlign: 'justify',
                    }}
                  >
                    {t("awaitingforApproval")}
                  </Typography>
                </Card>
              ) : (
                ''
              )}
            </div>
          ) : (
            <Grid container>{getUploadStatus()}</Grid>
          )}

          {!hidePictureAction ? (
            <Grid
              container
              justifyContent="space-between"
              sx={{ margin: '20px 0px' }}
            >
              <div style={{ marginTop: '-2px !important' }}>
                {pictureLikeAction ? (
                  <LikeGreenIcon
                    className={classes.icons}
                    onClick={
                      props.myProfile === false ? () => likeOtherPhotos() : ''
                    }
                  />
                ) : (
                  <LikeIcon3
                    className={classes.icons}
                    onClick={
                      props.myProfile === false ? () => likeOtherPhotos() : ''
                    }
                  />
                )}
                <CommentIcon className={classes.icons} />
              </div>
              <div style={{ marginTop: '5px !important' }}>
                <span className={classes.counts}>{pictureLikeCount}</span>
                <LikeIcon2 className={classes.icons} />
                <span className={classes.counts}>{pictureCommentCount}</span>
                <ChatIcon />
              </div>
            </Grid>
          ) : (
            ''
          )}
          <Carousel
            swipeable
            centerMode
            centerSlidePercentage={getSlidePercentage()}
            renderArrowPrev={(clickHandler) => (
              <div className={classes.leftArrow} onClick={clickHandler}>
                <ChevronLeftIcon className={classes.arrowIcons} />
              </div>
            )}
            renderArrowNext={(clickHandler) => (
              <div className={classes.rightArrow} onClick={clickHandler}>
                <ChevronRightIcon className={classes.arrowIcons} />
              </div>
            )}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
          >
            {props.myProfile ? renderImages() : renderOtherUserImages()}
          </Carousel>

          {props.myProfile ? (
            <Typography
              variant="body2"
              component="p"
              className={classes.text}
              sx={{ marginTop: '20px', marginBottom: '20px' }}
            >
              {pictureDescription}
            </Typography>
          ) : (
            <TextField
              variant="outlined"
              name="comment"
              placeholder={t("typeYourCommentHere")}
              onChange={(e) => handleCommentChange(e)}
              value={commentData}
              // value={values.username}
              // onChange={handleChange}
              // helperText={
              // 	errors.username &&
              // 	touched.username && (
              // 		<span className={classes.errorText}>
              // 			{errors.username}
              // 		</span>
              // 	)
              // }
              InputProps={{
                classes: {
                  input: classes.commentInputFields,
                  root: classes.inputRoot,
                },
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <SubmitIcon
                      style={{ cursor: 'pointer' }}
                      onClick={!commentButtonDisabled ? () => submitParentComment() : ''}
                    />
                  </InputAdornment>
                ),
              }}
              fullWidth
              sx={{ marginTop: '20px' }}
            />
          )}
          <Button
            variant="text"
            color="primary"
            className={classes.hideComments}
            sx={{
              marginTop: '12px',
              marginBottom: '10px',
              textTransform: 'capitalize',
            }}
            onClick={() => changeCommentButtonStatus()}
          >
            {!commentButtonStatus ? t("hideComments") : t("showComments")}
          </Button>
          {!commentButtonStatus ? (
            <List sx={{ maxHeight: '300px', overflow: 'auto' }}>
              {commentDetails.length > 0 ? (
                commentDetails.map((item) => {
                  return (
                    <div key={item}>
                      <ListItem key={item} sx={{ pt: 0 }}>
                        <Grid container xs={12} display={'flex'}>
                          <Grid item xs={1}>
                            <ListItemAvatar>
                              <Avatar
                                src={`${item.profile_pic}`}
                              ></Avatar>
                            </ListItemAvatar>
                          </Grid>

                          <Grid item xs={10} sx={{ ml: 1 }}>
                            <ListItemText
                              primary={
                                <Grid container alignItems="center">
                                  <Typography variant="body1" component="h6">
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    component="p"
                                    sx={{
                                      fontWeight: 600,
                                      color: '#7a797a',
                                      marginLeft: '6px',
                                    }}
                                  >
                                    {getTimeStamp(item.created_at)}
                                  </Typography>
                                </Grid>
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ p: 8, pt: 0, pb: 0, pr: 0 }}>
                            <ListItemText
                              secondary={
                                <Typography
                                  component="p"
                                  variant="body2"
                                  className={classes.commentText}
                                  sx={{ mb: '0px !important' }}
                                >
                                  {item.message}
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            alignItems="center"
                            sx={{ p: 8, pt: 0, pb: 0, pr: 0 }}
                          >
                            <ListItemText
                              secondary={
                                <Typography
                                  component="p"
                                  variant="body2"
                                  className={classes.commentText}
                                  sx={{
                                    mb: '0px !important',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    handleShowReplyComments(item.id)
                                  }
                                >
                                  {showReplyComments.id === item.id &&
                                    showReplyComments.replyCommentStatus
                                    ? t("hideReplayComments")
                                    : t("showReplayComments")}
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            alignItems="center"
                            sx={{ p: 8, pt: 0, pb: 0, pr: 0 }}
                          >
                            <LikeIcon2
                              onClick={() => likeGalleryComments(item.id)}
                              className={classes.icons}
                              style={{ cursor: 'pointer' }}
                            />
                            <span className={classes.counts}>{item.likes}</span>
                            <ChatIcon
                              onClick={() => handleCommentReply(item.id)}
                              className={classes.icons}
                              style={{ cursor: 'pointer' }}
                            />
                            <span
                              className={classes.counts}
                              onClick={() => handleCommentReply(item.id)}
                              style={{ cursor: 'pointer' }}
                            >
                              {t("reply")}
                            </span>
                          </Grid>
                          {showReplyComments.id === item.id &&
                            showReplyComments.replyCommentStatus ? (
                            <Grid
                              container
                              xs={12}
                              display={'flex'}
                              sx={{ pl: 6 }}
                            >
                              {item.reply.length > 0 ? (
                                item.reply.map((replyData) => (
                                  <>
                                    <Grid item xs={1} key={replyData}>
                                      <ListItemAvatar>
                                        <Avatar
                                          src={`${replyData.profile_pic}`}
                                        ></Avatar>
                                      </ListItemAvatar>
                                    </Grid>

                                    <Grid item xs={10} sx={{ ml: 1 }}>
                                      <ListItemText
                                        primary={
                                          <Grid container alignItems="center">
                                            <Typography
                                              variant="body1"
                                              component="h6"
                                            >
                                              {replyData.name}
                                            </Typography>
                                            <Typography
                                              variant="subtitle2"
                                              component="p"
                                              sx={{
                                                fontWeight: 600,
                                                color: '#7a797a',
                                                marginLeft: '6px',
                                              }}
                                            >
                                              {getTimeStamp(replyData.created_at)}
                                            </Typography>
                                          </Grid>
                                        }
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sx={{ p: 8, pt: 0, pb: 0, pr: 0 }}
                                    >
                                      <ListItemText
                                        secondary={
                                          <Typography
                                            component="p"
                                            variant="body2"
                                            className={classes.commentText}
                                            sx={{ mb: '0px !important' }}
                                          >
                                            {replyData.message}
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                  </>
                                ))
                              ) : (
                                <Typography
                                  component="p"
                                  variant="body2"
                                  className={classes.commentText}
                                  sx={{ mb: '0px !important' }}
                                >
                                  {t("noComments")}
                                </Typography>
                              )}
                            </Grid>
                          ) : (
                            ''
                          )}
                          {replyCommentInput.id === item.id &&
                            replyCommentInput.replyInputStatus ? (
                            <Grid
                              item
                              xs={12}
                              alignItems="center"
                              sx={{ p: 8, pt: 0, pb: 0, pr: 0 }}
                            >
                              <TextField
                                variant="outlined"
                                name="Levelcomment"
                                placeholder={t("typeYourCommentHere")}
                                onChange={(e) => handleCommentChange(e)}
                                value={LevelCommentData}
                                // value={values.username}
                                // onChange={handleChange}
                                // helperText={
                                // 	errors.username &&
                                // 	touched.username && (
                                // 		<span className={classes.errorText}>
                                // 			{errors.username}
                                // 		</span>
                                // 	)
                                // }
                                InputProps={{
                                  classes: {
                                    input: classes.commentInputFields,
                                    root: classes.inputRoot,
                                  },
                                  disableUnderline: true,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <SubmitIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={!commentButtonDisabled ? () => submitComment(item) : ""}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                fullWidth
                                sx={{ marginTop: '20px' }}
                              />
                            </Grid>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </ListItem>
                    </div>
                  );
                })
              ) : (
                <Typography variant="body1" component="p">
                  {t("noComments")}
                </Typography>
              )}
            </List>
          ) : (
            ''
          )}
        </CardContent>
      </Card>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={enableGalleryPicMenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{
            justifyContent: 'center',
            pt: 0,
            pb: 0,
            mt: 1,
            color: '#0f7fb5',
          }}
          onClick={() => changeAsProfilePic()}
        >
          {t("makeProfilePic")}
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ justifyContent: 'center', pt: 0, pb: 0, color: '#0f7fb5' }}
        >
          <a href={downloadableUrl} download target="_blank" style={{ textDecoration: 'none', color: '#0f7fb5' }}>
            {t("savePhoto")}
          </a>
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ justifyContent: 'center', pt: 0, pb: 0, color: '#0f7fb5' }}
          onClick={() => copyToClipboard(othermainPicture)}
        >
          {t("copyPhoto")}
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ justifyContent: 'center', pt: 0, pb: 0, color: '#0f7fb5' }}
          onClick={() => socialSharing(true)}
        >
          {t("sharePhoto")}
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ justifyContent: 'center', pt: 0, pb: 0, color: '#0f7fb5' }}
          onClick={() => sendInChat()}
        >
          {t("sendInchat")}
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ justifyContent: 'center', pt: 0, pb: 0, color: '#0f7fb5' }}
          onClick={() => confirmPrivacySetting()}
        >
          {t("setPrivacyPhoto")}
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ justifyContent: 'center', pt: 0, pb: 0, color: '#0f7fb5' }}
          onClick={() => deletePicture()}
        >
          {t("deletePhoto")}
        </MenuItem>
        <Divider />
        <MenuItem sx={{ justifyContent: 'center', pt: 0, pb: 0 }}>
          {' '}
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{
              marginRight: '10px',
              borderRadius: '5px',
              backgroundColor: '#0f7fb5',
            }}
            onClick={handleClose}
          >
            {t("cancel")}
          </Button>
        </MenuItem>
      </Menu>
      <Dialog open={uploadModal} onClose={handleClose}>
        <DialogContent sx={{ width: 'auto', height: 'auto' }}>
          <List sx={{ pt: 0 }}>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="close">
                  <CancelIcon onClick={handleClose} />
                </IconButton>
              } disablePadding>
              <ListItemButton sx={{ pl: 0 }}>
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <ArrowBackOutlinedIcon
                    onClick={
                      files.length > 0
                        ? () => setFile([])
                        : () => setUploadModal(false)
                    }
                    className={classes.arrowIcon}
                  />
                </ListItemIcon>
                <ListItemText primary={t("upload")} />
              </ListItemButton>
            </ListItem>
          </List>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={12} lg={12}>
              <Dropzone
                multiple={true}
                onDrop={(acceptedFiles) =>
                  setFile(
                    acceptedFiles.map((file) =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      })
                    )
                  )
                }
                accept="image/*"
              >
                {({ getRootProps, getInputProps }) => (
                  <Grid container justify="center">
                    {files.length > 0 && files[0].size <= 5242880 ? (
                      <>
                        {' '}
                        <section className={classes.uploadDragableUpload}>
                          <input {...getInputProps()} />
                          <div className={classes.uploadPlaceholderCt}>
                            {files.map((file, index) => {
                              return (
                                <img
                                  key={`upload-file-${index}`}
                                  src={file.preview}
                                  alt={file.name}
                                />
                              );
                            })}
                          </div>
                        </section>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            variant="p"
                            sx={{ ml: 1, fontSize: '15px' }}
                          >
                            {t("selectPrivacy")}<span style={{ color: 'red' }}>*</span>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <List>
                            <ListItem
                              secondaryAction={
                                <Checkbox
                                  edge="end"
                                  checked={picSecurity === 'public' ? true : false}
                                  onChange={
                                    picSecurity === 'public'
                                      ? () => setPicSecurity('')
                                      : () => setPicSecurity('public')
                                  }
                                />
                              }
                              disablePadding
                            >
                              <ListItemButton>
                                <ListItemIcon sx={{ minWidth: 'auto' }}>
                                  <PublicIcon />
                                </ListItemIcon>
                                <ListItemText primary={t("publicPrivacy")} />
                              </ListItemButton>
                            </ListItem>
                            <ListItem
                              secondaryAction={
                                <Checkbox
                                  edge="end"
                                  checked={picSecurity === 'private' ? true : false}
                                  onChange={
                                    picSecurity === 'private'
                                      ? () => setPicSecurity('')
                                      : () => setPicSecurity('private')
                                  }
                                />
                              }
                              disablePadding
                            >
                              <ListItemButton>
                                <ListItemIcon sx={{ minWidth: 'auto' }}>
                                  <PeopleAltOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t("privatePrivacy")} />
                              </ListItemButton>
                            </ListItem>
                            <ListItem
                              secondaryAction={
                                <Checkbox
                                  edge="end"
                                  checked={
                                    picSecurity === 'superPrivate' ? true : false
                                  }
                                  onChange={
                                    picSecurity === 'superPrivate'
                                      ? () => setPicSecurity('')
                                      : () => setPicSecurity('superPrivate')
                                  }
                                />
                              }
                              disablePadding
                            >
                              <ListItemButton>
                                <ListItemIcon sx={{ minWidth: 'auto' }}>
                                  <PeopleAltOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t("superPrivatePrivacy")} />
                              </ListItemButton>
                            </ListItem>
                          </List>
                   
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <TextareaAutosize
                            onChange={(e) => handleCaptionChange(e)}
                            placeholder={t("enterCaptionHere")}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} md={12} lg={12} sm={12} display='flex' justifyContent={'center'}>
                          <section className={classes.uploadDragableUpload}>
                            <input {...getInputProps()} />
                            <div
                              {...getRootProps({
                                onClick: (event) => console.log(event),
                              })}
                              className={classes.uploadPlaceholderCt}
                            ></div>
                            <AddCircleIcon
                              {...getRootProps()}
                              className={classes.addImage}
                            />
                          </section>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12} display='flex' justifyContent={'center'}>
                          <Typography variant="h6" sx={{ fontSize: '13px' }}>
                            {t("dragPhotoHere")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sm={12} display='flex' justifyContent={'center'}>
                          <Typography variant="p" sx={{ mt: 2 }}>
                            {t("-or-")}
                          </Typography>
                        </Grid>
                        {files.length > 0 && files[0].size > 5242880 ? (
                          <Typography sx={{ ml: 1.5, color: 'red' }}>
                            File size too large.
                          </Typography>
                        ) : (
                          ''
                        )}
                        <Grid item xs={12} md={12} lg={12} sm={12} display='flex' justifyContent={'center'} >
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            {...getRootProps({
                              onClick: (event) => console.log(event),
                            })}
                            sx={{
                              borderRadius: '5px',
                              fontSize: '10px',
                              mb: 1,
                              mt: 2,
                            }}
                          >
                            {t("chooosePhoto")}
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
              </Dropzone>
            </Grid>
          </Grid>
          {files.length > 0 ? (
            <DialogActions sx={{ justifyContent: 'center' }}>
              {!loaderStatus ? <Button
                variant="contained"
                color={picSecurity !== '' ? "primary" : "secondary"}
                size="small"
                onClick={picSecurity !== '' ? () => galleryUpload() : ''}
                sx={{ borderRadius: '5px' }}
                title={picSecurity === '' ? t("privacyRequired") : ''}
              >
                {t("upload")}
              </Button> :
                <LoadingButton
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  {t("uploading")}
                </LoadingButton>}
            </DialogActions>
          ) : (
            ''
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={privacySettingsModal} onClose={handleClose}>
        <DialogContent sx={{ width: '20rem', height: '10rem' }}>
          <CancelIcon className={classes.closeIcon} onClick={handleClose} />
          <Typography variant="body1" component="p">
            {t("picturePrivacySettings")}
          </Typography>
          <Grid container justifyContent="center">
            {imageVisibilityStatus != 0 ? (
              <Grid
                sx={{ mt: 1 }}
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" sx={{ ml: 1, fontSize: '15px' }}>
                  <PublicIcon className={classes.arrowIcon} />
                  {t("publicPrivacy")}
                </Typography>
                <Checkbox
                  checked={picSecurity === 'public' ? true : false}
                  onChange={
                    picSecurity === 'public'
                      ? () => setPicSecurity('')
                      : () => setPicSecurity('public')
                  }
                />
              </Grid>
            ) : (
              ''
            )}
            {imageVisibilityStatus != 1 ? (
              <Grid
                item
                sx={{ mt: 1 }}
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" sx={{ ml: 1, fontSize: '15px' }}>
                  <PeopleAltOutlinedIcon className={classes.arrowIcon} />
                  {t("privatePrivacy")}
                </Typography>
                <Checkbox
                  checked={picSecurity === 'private' ? true : false}
                  onChange={
                    picSecurity === 'private'
                      ? () => setPicSecurity('')
                      : () => setPicSecurity('private')
                  }
                />
              </Grid>
            ) : (
              ''
            )}
            {imageVisibilityStatus != 2 ? (
              <Grid
                sx={{ mt: 1 }}
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" sx={{ ml: 1, fontSize: '15px' }}>
                  <LockOutlinedIcon className={classes.arrowIcon} />
                  {t("superPrivatePrivacy")}
                </Typography>
                <Checkbox
                  checked={picSecurity === 'superPrivate' ? true : false}
                  onChange={
                    picSecurity === 'superPrivate'
                      ? () => setPicSecurity('')
                      : () => setPicSecurity('superPrivate')
                  }
                />
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => changeProfilePicVisibility()}
              sx={{ borderRadius: '5px' }}
            >
              {t("changeSettings")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={sendInChatAlert}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ minWidth: 300 }}>{t("sendToChat")}</DialogTitle>
        <DialogContent dividers={false}>
          <CancelIcon className={classes.closeIcon} onClick={handleClose} />
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={selectedChatId}
                onChange={handleChange}
              >
                {props.chatHistory.length > 0 ? (
                  props.chatHistory.map((item) => (
                    <Grid container xs={12} sx={{ mt: 2 }}>
                      <Grid item xs={6} key={item}>
                        {item.is_online === 1 ? (
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            variant="dot"
                          >
                            <Avatar
                              alt="avatar"
                              src={`${item.pictures}`}
                              sx={{
                                height: '70px',
                                width: '70px',
                                marginRight: '10px',
                                marginLeft: '10px',
                                border: '2px solid #F7B573',
                              }}
                            />
                          </StyledBadge>
                        ) : (
                          <Avatar
                            alt="avatar"
                            src={`${item.pictures}`}
                            sx={{
                              height: '70px',
                              width: '70px',
                              marginRight: '10px',
                              marginLeft: '10px',
                              border: '2px solid #F7B573',
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={4} sx={{ alignSelf: 'center' }}>
                        <Typography variant="p">{item.username}</Typography>
                      </Grid>

                      <Grid item xs={2} sx={{ alignSelf: 'center' }}>
                        <FormControlLabel
                          value={item.user_id}
                          control={<Radio />}
                          label=""
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Typography variant='p'>{t("noMessages")}</Typography>
                )}
              </RadioGroup>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        {props.chatHistory.length > 0 ? <DialogActions>
          <Button
            variant="contained"
            color={selectedChatId != '' ? 'primary' : 'secondary'}
            size="small"
            onClick={selectedChatId != '' ? () => sendGalleryPic() : ''}
          >
            {t("send")}
          </Button>
        </DialogActions> : ""}
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    photoList: state.GalleryReducer.photoList,
    otherUserDetails: state.CommonReducer.userDataById,
    likePhotoStatus: state.GalleryReducer.likePhotoStatus,
    photoUploadStatus: state.GalleryReducer.photoUploadStatus,
    commentStatus: state.GalleryReducer.commentStatus,
    commentDetails: state.GalleryReducer.commentData,
    eligibleCount: state.GalleryReducer.eligibleCount,
    setAsProfilePicStatus: state.GalleryReducer.setAsProfilePicStatus,
    picVisibilityChangeStatus: state.GalleryReducer.picVisibilityChangeStatus,
    picDeleteStatus: state.GalleryReducer.picDeleteStatus,
    likeForCommentStatus: state.GalleryReducer.likeForCommentStatus,
    galleryUnlockedStatus: state.GalleryReducer.galleryUnlockedStatus,
    shareToChatReqData: state.GalleryReducer.shareToChatReqData,
    userCoins: state.GalleryReducer.userCoins,
    chatHistory: state.ChatReducer.chatHistory,
    postMessageStatus: state.ChatReducer.postMessageStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGalleryPhotoList: (token) =>
      dispatch(galleryAction.getGalleryPhotoList(token)),
    getGalleryPhotoDetails: (token, imageID) =>
      dispatch(galleryAction.getGalleryPhotoDetails(token, imageID)),
    getUserByID: (token, requestData) =>
      dispatch(commonAction.getUserByID(token, requestData)),
    galleryPhotosUpload: (token, photos) =>
      dispatch(galleryAction.uploadGalleryPhotos(token, photos)),
    LikeOtherMemberPhotos: (data, token) =>
      dispatch(galleryAction.LikeOtherMemberPhotos(data, token)),
    CommentOtherMemberPhotos: (data, token) =>
      dispatch(galleryAction.CommentOtherMemberPhotos(data, token)),
    setAsProfilePic: (data, token) =>
      dispatch(galleryAction.setAsProfilePic(data, token)),
    changePicVisibility: (data, token) =>
      dispatch(galleryAction.setPicVisibility(data, token)),
    deleteGalleryPicture: (data, token) =>
      dispatch(galleryAction.deleteGalleryPicture(data, token)),
    profileChangeStatusNull: () =>
      dispatch(galleryAction.setPhotoChangetatusNull()),
    LikeForComment: (data, token) =>
      dispatch(galleryAction.LikeForComment(data, token)),
    getChatHistoryData: (requestData) =>
      dispatch(chatAction.getChatHistoryData(requestData)),
    PostNewMessage: (requestData, token) =>
      dispatch(chatAction.PostNewMessage(requestData, token)),
    userGeneralDetailsService: (requestData) =>
      dispatch(userDetailsAction.userGeneralDetailsService(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryCard);
